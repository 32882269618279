<template>
  <div class="assessBox">
    <div class="app-container">
      <div class="titlebox" style="margin-bottom:20px" :style="$route.name=='MatchplanDetail'?'margin:20px':''">
        <el-button size="medium"
                   type="text"
                   @click="$router.go(-1)">返回</el-button>

        <div class="title">{{title}}</div>
      </div>
      <div class="viewbox">
        <div class="baseWarp">
          <div class="baseBox">
            <div class="baseBox-name">{{info.name}}</div>
            <div class="baseBox-status">{{info.statusName}}</div>
            <div class="baseBox-date">{{info.startTime}}--{{info.endTime}}</div>
          </div>
          <div class="baseBox">
            <div class="peopleBox">
              <img src="../../../assets/total.png">
              <span>总共{{info.sumAllCount}}人</span>
            </div>
            <div class="peopleBox">
              <img src="../../../assets/计划详情-初评@3x.png">
              <span>已初评{{info.sumFirstCount}}人</span>
            </div>
            <div class="peopleBox">
              <img src="../../../assets/计划详情-终评@3x.png">
              <span>已终评{{info.sumEndCount}}人</span>
            </div>
            <div class="peopleBox">
              <img src="../../../assets/计划详情-确定@3x.png">
              <span>已确认{{info.sumAffirmCount}}人</span>
            </div>
             <div class="peopleBox">
              <img src="../../../assets/计划详情-驳回@3x.png">
              <span>已驳回{{info.rejectCount}}人</span>
            </div>
            <div class="peopleBox">
              <img src="../../../assets/计划详情-撤销@3x.png">
              <span>已取消{{info.cancelCount}}人</span>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div>
          <div class="model">
            <div class="title-icon">评价模型</div>
            <div class="resultWrap">
              <div>
                <div style="margin-top:15px;clear:both;"
                     v-for="(item,index) in info.jobFitModelRequests"
                     :key="index">
                  <label style="font-weight:normal;margin-right:10px;">{{item.modelName}}</label>
                  <label class="right_label">
                    <el-tag style="margin-right:10px; margin-bottom:10px; margin-top:10px;"
                            v-for="(tag,tIndex) in item.familyNameList"
                            :key="tIndex">{{tag}}</el-tag>
                    <div class="resultWrap">
                      <div>
                        <etcbox v-if="item.jobEtcRequests != null"
                                :isInfo="true"
                                :defaultJobEtcList="item.jobEtcRequests"></etcbox>
                        <!-- <div class="circleBox"
                             v-if="item.jobEtcRequests != null">
                          <span class="circle circle-green">高</span>
                          <span class="el-icon-bottom"
                                style="margin:20px 0"></span>
                          <span class="circle circle-red">低</span>
                        </div>
                        <div class="etcBox">
                          <div v-for="(etc,eindex) in item.jobEtcRequests"
                               :key="eindex"
                               style="margin-bottom:20px;">
                            <div>
                              <span style="margin:0 15px;">{{etc.jobEtc}}</span> =>
                              <span style="display:inline-block;width:30px;text-align:center;margin:0 15px;">{{etc.minScore}}</span>~
                              <span style="display:inline-block;width:30px;text-align:center;margin:0 15px;">{{etc.maxScore}}</span>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="model" v-if="useCustomeEtc!=info.jobFitModelRequests.length">
            <div class="title-icon">职等划分</div>
            <div class="resultWrap">
              <div>
                <etcbox :isInfo="true"
                        :defaultJobEtcList="info.defaultJobEtcList"></etcbox>
                <!-- <div class="circleBox">
                  <span class="circle circle-green">高</span>
                  <span class="el-icon-bottom"
                        style="margin:20px 0"></span>
                  <span class="circle circle-red">低</span>
                </div>
                <div class="etcBox">
                  <div v-for="(item,index) in info.defaultJobEtcList"
                       :key="index"
                       style="margin-bottom:20px;">
                    <div>
                      <span style="margin:0 15px;">{{item.jobEtc}}</span> =>
                      <span style="display:inline-block;width:30px;text-align:center;margin:0 15px;">{{item.minScore}}</span>~
                      <span style="display:inline-block;width:30px;text-align:center;margin:0 15px;">{{item.maxScore}}</span>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div>
          <div class="model">
            <div class="title-icon">评价范围</div>
            <div class="resultWrap">
              <div v-if="info.jobFitScopeRequest && info.jobFitScopeRequest.companyType==0">
                <div class="row">{{comname}}</div>
                <div class="row">
                  <el-checkbox :true-label="1"
                               :false-label="0"
                               disabled
                               v-model="info.jobFitScopeRequest.isByDepartment">按部门</el-checkbox>
                  <el-tag style="margin-right:10px;margin-left:10px;margin-bottom:10px;"
                          v-for="(org,orgIndex) in info.jobFitScopeRequest.orgNameList"
                          :key="orgIndex">{{org}}</el-tag>
                </div>
                <div class="ml row">
                  <el-checkbox-group v-model="info.jobFitScopeRequest.jobstatusOptions">
                    <el-checkbox v-for="(status,staIndex) in statusList"
                                 :key="staIndex"
                                 disabled
                                 :label="status.dictId">{{status.dictName}}</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="ml row">
                  <el-checkbox-group v-model="info.jobFitScopeRequest.hireformOptions">
                    <el-checkbox v-for="(status,staIndex) in hireFormList"
                                 :key="staIndex"
                                 disabled
                                 :label="status.dictId">{{status.dictName}}</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="ml row"
                     style="margin-bottom:20px;margin-top:20px;">
                  {{info.jobFitScopeRequest.entrytimeLimit}}之后入职的员工不参加评价（含当天）
                </div>
                <div class="ml row">
                  {{info.jobFitScopeRequest.assesstimeLimit}}之后已评价过的员工不参加评价（含当天）
                </div>

              </div>
              <div v-if="info.jobFitScopeRequest && info.jobFitScopeRequest.companyType==1">
                <div class="row">{{info.jobFitScopeRequest.customerComName}}</div>
                <div class="ml">
                  <el-checkbox :true-label="1"
                               :false-label="0"
                               disabled
                               v-model="info.jobFitScopeRequest.isByDepartment">按部门</el-checkbox>
                  <span style="margin-left:10px;">{{info.jobFitScopeRequest.toplevelDeptname}}</span>
                  <span style="margin-left:10px;">{{info.jobFitScopeRequest.seclevelDeptname}}</span>
                </div>
                <div class="ml row"
                     style="margin-bottom:20px;margin-top:20px;">
                  {{info.jobFitScopeRequest.entrytimeLimit}}之后入职的员工不参加评价（含当天）
                </div>
                <div class="ml row">
                  {{info.jobFitScopeRequest.assesstimeLimit}}之后已评价过的员工不参加评价（含当天）
                </div>
              </div>
            </div>
          </div>
          <div class="model"
               style="vertical-align: top;">
            <div class="title-icon">评价人</div>
            <div class="resultWrap">
              <div class="row"
                   v-for="(assess,index) in info.jobFitAssessorRequests"
                   :key="index">
                <span style="display:flex;align-items:center;"
                      v-if="(assess.assessorType == '1' || assess.assessorType == '2') ">
                  {{assess.assessorName}}（{{assess.assessorTypeName}}）
                  <span v-if=" info.jobFitScopeRequest.companyType == '1'"
                        class="qrcode-trigger__text">
                    <img src="../../../assets/code.png"
                         width="15px;">
                    <el-button type="text"
                               @click="getCode(assess.assessorType)">二维码</el-button>
                  </span>
                </span>
                <span v-if="assess.assessorType == '3' || assess.assessorType == '4'">{{assess.assessorName}}
                  （<span>{{assess.assessorTypeName}}</span>）
                </span>
                <span v-if="assess.assessorType == '5'">{{assess.assessorName}}
                  （<span>{{assess.assessorNameList.join(",")}}</span>）
                </span>
              </div>
            </div>
            <div class="title-icon">确认人</div>
            <div class="resultWrap">
              <span>{{info.confirmerName}}</span>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div>
          <div class="model"
               style="width:100%">
            <div class="title-icon">备注</div>
            <div class="resultWrap">
              <div style="line-height: 24px;">{{info.remark}}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <el-dialog style="width:50%;margin: 0 auto;"
                   top="20vh"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible">
          <H6 style="text-align:center;color:#F64A0D;">温馨提示:建议使用手机自带浏览器扫描打开</H6>
          <figure style="text-align:center">
            <img width="200px;"
                 :src="dialogImage.url"
                 alt="">
            <figcaption style="text-align:center;">
              <el-button type="text"
                         @click="copyClip(dialogImage.con)">复制链接</el-button>
            </figcaption>
          </figure>

        </el-dialog>
      </div>
    </div>
  </div>

</template>
<script>
import * as api from '@/api/peopelPostMatch';
import etcbox from '@/components/peoplePostMatch/etcBox';
import { copyClip } from '../../../utils/util';
export default {
  components: {
    etcbox
  },

  data () {
    return {
      id: '',
      useCustomeEtc: false,
      title: '人岗匹配考核详情',
      info: [],
      comname: '',
      statusList: [],
      hireFormList: [],
      assessorList: [],
      dialogVisible: false,
      dialogImage: {},
      loadding: false
    };

  },

  created () {
  },
  mounted () {
    this.$nextTick(function () {
      this.statusList = this.$store.state.app.dict.filter((item) =>
         item.groupId == 'status' && item.dictId != '3' // 过滤掉离职
      );
      this.hireFormList = this.$store.state.app.dict.filter((item) => item.groupId == 'informal');
      this.assessorList = this.$store.state.app.dict.filter((item) => item.groupId == 'AssessorType');
      this.id = this.$route.query.id;
      this.getDetail();
    });
  },
  methods: {
    copyClip (str) {
      copyClip(str);
      this.$message.success({
        message: '复制成功!'
      });
    },
    getDetail () {
      api.getPlanInfo({ request: { id: this.id } }).then((res) => {
        this.info = res;
        if (this.info.jobFitScopeRequest.companyType == '0') {
          this.comname = sessionStorage.getItem('comName');
        }
        this.info.jobFitModelRequests.map((it) => {
          if (it.isCustomeEtc == 1) {
            this.useCustomeEtc++;
          }
        });
        this.info.jobFitScopeRequest.isByDepartment = parseInt(this.info.jobFitScopeRequest.isByDepartment);
      });
    },
    getCode (type) {
      api.getCode({ request: { scheduleId: this.id, assessorType: type } }).then((res) => {
        this.dialogVisible = true;
        this.dialogImage = res;

      });
    }
  }
};
</script>
<style lang="scss" scoped>
.assessBox .app-container .viewbox .level_box_now {
  font-weight: bold;
}
.qrcode-trigger__text {
  display: flex;
  align-items: center;
}
.inputDisable {
  background: #f5f7fa;
  color: #c0c4cc;
  cursor: not-allowed;
}
.assessBox {
  .app-container {
    color: #484848;
    padding: 0;
    background-color: #f6f7fb;
    .titlebox {
      text-align: center;
      line-height: 17px;
      background: #fff;
      font: bold 18px/25px "Microsoft YaHei";
      padding: 14px 40px;
      > .el-button {
        float: left;
        color: #484848;
        transform: translateY(-8px);
        font-size: 16px;
      }
      text-align: center;
      line-height: 17px;
      font: bold 18px/25px "Microsoft YaHei";
      margin-bottom: 20px;
    }
    .viewbox {
      margin: 0 20px;
      background: #ffffff;
      min-height: 300px;
      padding: 50px;
    }
  }
}
.baseWarp {
  display: flex;
  .baseBox {
    width: calc(100% / 2);
    height: 100px;
    line-height: 40px;
    .baseBox-name {
      font:bold 24px PingFangSC-Medium;;
      color: #2B3642;
      letter-spacing: 0;
    }
    .baseBox-date {
      font-size: 16px;
      color: #777E8C;
    }
    .baseBox-status {
      font-size: 16px;
      color: #777E8C;
    }
    .peopleBox {
      display: inline-block;
      width: calc(100% / 6);
      text-align: center;
      margin-top: 20px;
      img {
        width: 40px;
        height: 40px;
      }
      span {
        display: block;
      }
    }
  }
  .baseBox:first-of-type {
    width: calc(100% / 3);
  }
  .baseBox:last-of-type {
    width: calc(75%);
  }
}
.resultWrap {
  padding: 20px 30px;
}
.title-icon {
  font-size: 18px;
  &:before {
    display: inline-block;
    width: 8px;
    height: 20px;
    background: #498df0;
    content: " ";
    border-radius: 5px;
    vertical-align: top;
    margin-right: 5px;
  }
}
.left_label {
  margin-right: 30px;
  color: #999999;
  text-align: right;
  width: 130px;
  vertical-align: top;
  display: inline-block;
}
.right_label {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  font-weight: normal;
}
.rowResult div {
  display: inline-block;
  width: calc(100% / 2);
  margin-top: 20px;
}
.model {
  display: inline-block;
  width: calc(100% / 2);
  vertical-align: top;
}
.circleBox {
  float: left;
  text-align: center;
  margin-top: 10px;
}
.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  line-height: 30px;
  text-align: center;
  color: #fff;
}
.circle-green {
  background: rgba(7, 204, 95, 0.8);
}
.circle-red {
  background: rgba(255, 69, 0, 1);
}
.etcBox {
  margin-left: 20px;
  float: left;
  margin-top: 10px;
}
.row {
  margin-bottom: 20px;
}
</style>

